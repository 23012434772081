import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import Img from "gatsby-image"
import logger from "../lib/logger"

const log = logger.extend("icon")

const Icon = ({ name }) => {
  const data = useStaticQuery(graphql`
    query {
      allFile(filter: { extension: { eq: "png" } }) {
        edges {
          node {
            base
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  `)

  const image = data.allFile.edges.find(
    image => image.node.base.split(".")[0] === name
  )
  if (!image) {
    log("Couldn't find image with name", name)
    return null
  }

  return (
    <Img
      fluid={image.node.childImageSharp.fluid}
      alt={name.split("_").join(" ")} // only use section of the file extension with the filename
    />
  )
}

export default Icon
