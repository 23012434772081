import React, { useEffect, useState } from "react"
import AriaModal from "react-aria-modal"

const Modal = ({
  children,
  show,
  onEnter,
  onExit,
  title = "Modal",
  ...props
}) => {
  const [modalContentClass, setModalContentClass] = useState("")
  useEffect(() => {
    setTimeout(() => {
      setModalContentClass(show ? "opacity-100" : "")
    }, 300)
  }, [show])

  return (
    <AriaModal
      titleText={title}
      onEnter={onEnter}
      onExit={onExit}
      focusDialog={true}
      mounted={show}
      underlayColor={false}
      underlayClass={"bg-opacity-75 bg-black p-10"}
      verticallyCenter={true}
      {...props}
    >
      <div
        className={`opacity-0 bg-white transition-opacity ease-in-out duration-500 text-center p-10 rounded-sm ${modalContentClass}`}
      >
        {children}
      </div>
    </AriaModal>
  )
}

export default Modal
