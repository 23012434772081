export function trackEvent({ type = "click", category, label, href }) {
  if (typeof window === "undefined" || !window.gtag) {
    return
  }

  if (!label) {
    console.error("No label passed for event")
    return
  }

  window.gtag("event", type, {
    event_category: category,
    event_label: label,
    transport_type: "beacon",
    event_callback: function () {
      if (href) {
        document.location = href
      }
    },
  })
}
